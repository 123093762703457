import React, {useState} from 'react'
import SecondaryButton from "../../buttons/secondary-button";
import {useTheme} from "@mui/system";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/index.reducers";
import {Dispatch} from "redux";
import {UserActionTypes} from "../../../redux/types/user-type";
import {users} from "../../../utils/firebase.utils";
import {updateUser} from "../../../redux/actions/user-actions";
import DialogWrapper from "../../dialogs/dialog-wrapper";
import Grid from "@mui/material/Grid";
import UploadPictureDialog from "./upload-picture-dialog";
import {environment} from "../../../environments/quickTaskEnvironemt";
import {isQuickTaskProject} from "../../../utils/constant.urls";
const QuikTaskMainColor = environment.mainColor;

const PictureOptions = (props) => {

    const {userData} = useSelector((state: RootState) => state.userData)
    const dispatch = useDispatch<Dispatch<UserActionTypes>>()

    const [openDialog, setOpenDialog] = React.useState(false);
    const [uploadedImage, setUploadedImage] = useState(null)

    const hiddenFileInput = React.useRef(null);


    const handleClick = () => {
        hiddenFileInput.current.click();
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const removeCurrentPicture = () => {
        users.doc(userData.uid).set({profilePicture: null}, {merge: true}).then(() => {
            userData.profilePicture = null;
            dispatch(updateUser(userData))
            props.onClose();
        })
    }
    const onImageChange = event => {
        if (event.target.files && event.target.files[0] ) {
             const type=event.target.files[0].type.split('/')
             if( type[0]==='image'){
                  let img = event.target.files[0];
                  setUploadedImage(URL.createObjectURL(img))
                  setOpenDialog(true)
             }
        }
    };

    const theme = useTheme();
    return (
      <div style={{width: "250px"}}>
        <>
          {userData.profilePicture ? (
            <SecondaryButton
              background={theme.palette.background.paper}
              textColor={isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main}
              disabled={false}
              style={{maxWidth: "unset", width: "100%", border: "2.5px solid #4E3EFD"}}
              onClick={removeCurrentPicture}
            >
              Remove Current Picture
            </SecondaryButton>
          ) : null}
          <div style={{height: "20px"}} />
          <SecondaryButton
            background={isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main}
            textColor={"white"}
            disabled={false}
            style={{maxWidth: "unset", width: "100%", border: "2.5px solid rgb(240,240,240)"}}
            onClick={handleClick}
          >
            Upload Picture
          </SecondaryButton>
          <input
            type={"file"}
            ref={hiddenFileInput}
            accept="image/*"
            onChange={onImageChange}
            style={{display: "none"}}
          />
        </>
        <input
          type={"file"}
          ref={hiddenFileInput}
          accept="image/*"
          onChange={onImageChange}
          style={{display: "none"}}
        />

        <DialogWrapper
          root
          title={"Edit Profile Picture"}
          onClose={handleCloseDialog}
          open={openDialog}
          height={"500px"}
        >
          <div style={{height: "500px", display: "flex", flexDirection: "column"}}>
            <div style={{height: "2px"}} />
            <UploadPictureDialog
              onClose={handleCloseDialog}
              onCloseRoot={props.onClose}
              userImage={uploadedImage}
            />
          </div>
        </DialogWrapper>
      </div>
    );
}

export default PictureOptions
