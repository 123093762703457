import React from 'react';
import {Grid} from "@mui/material";
import Text from "../../components/data-display/text";
import CreditCardHandler from "./credit-card-handler";
import BankAccountHandler from "../bank-account-handler/bank-account-handler";
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/index.reducers';

const BanksAndCardsTab = () => {
  const { userData } = useSelector((state: RootState) => state.userData);
  return (
     userData?.verifiedStatus==="verified"?
     <div>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={6}>
            <Text variant="overline" style={{ color: "grey" }}>
              your cards
            </Text>
            <CreditCardHandler/>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Text variant="overline" style={{ color: "grey" }}>
              bank account
            </Text>
            <BankAccountHandler/>
          </Grid>
        </Grid>
      </div>:
       <div>
       <Grid container spacing={4}>
         <Grid item xs={12} md={6} lg={6}>
           <Text variant="overline" style={{ color: "grey" }}>
             your cards
           </Text>
           <CreditCardHandler/>
         </Grid>
       </Grid>
     </div>
  );
};

export default BanksAndCardsTab;
