import React from 'react';
import FormErrorText from '../../data-display/form-error-text';
import Form from '../../forms/form-wrapper';
import FormInput from '../../forms/form-input';
import {users} from '../../../utils/firebase.utils';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from "../../../redux/index.reducers";
import {Dispatch} from "redux";
import {UserActionTypes} from "../../../redux/types/user-type";
import {updateUser} from "../../../redux/actions/user-actions";
import DialogBottomButton from "../../dialogs/dialog-bottom-button";

const CommunicationEmailForm = (props) => {

    const {userData} = useSelector((state: RootState) => state.userData);
    const dispatch = useDispatch<Dispatch<UserActionTypes>>();

    const saveCommunicationEmail = (values: Record<string, any>) => {
        return new Promise((resolve) => {
            users
                .doc(userData.uid)
                .set({communicationEmail: values.communicationEmail}, {merge: true})
                .then(() => {
                    userData.communicationEmail = values.communicationEmail;
                    dispatch(updateUser(userData));
                    resolve("Success");
                    props.onClose();
                })
                .catch((err) => {
                    console.log(err);
                });
        })
    };

   

    return (
        <Form
            onSubmit={saveCommunicationEmail}
            initialValues={{communicationEmail : userData.communicationEmail}}
        >
            <FormInput
                label={'Communication Email'}
                placeholder={'Communication Email'}
                name={'communicationEmail'}
                type={'email'}
                required               
            />
            <FormErrorText/>
            <div style={{height:"400px"}}></div>
            <DialogBottomButton showBackgroundFade={true}>
                Save
            </DialogBottomButton>
        </Form>
    );
};

export default CommunicationEmailForm;
