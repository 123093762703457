import React, {useCallback, useState} from 'react';
import SecondaryButton from "../../buttons/secondary-button";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/index.reducers";
import Slider from "@mui/material/Slider";
import {useTheme} from "@mui/system";
import Cropper from 'react-easy-crop';
import Text from "../../data-display/text";
import getCroppedImg from "./cropImage";
import {storage, users} from "../../../utils/firebase.utils";
import {updateUser} from "../../../redux/actions/user-actions";
import {Dispatch} from "redux";
import {UserActionTypes} from "../../../redux/types/user-type";
import {HTML5_FMT} from "moment";
import {endPoint, isQuickTaskProject, storageFB} from "../../../utils/constant.urls";
import {environment} from "../../../environments/quickTaskEnvironemt";
const QuikTaskMainColor = environment.mainColor;
const styles = {
  cropContainer: {
    position: "relative",
    width: "100%",
    height: 350,
  },
  controls: {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  sliderContainer: {
    display: "flex",
    flex: "1",
    alignItems: "center",
  },

  slider: {
    padding: "22px 0px",
    flexDirection: "row",
    alignItems: "center",
    margin: "0 16px",
    width: "250px",
  },
};

const UploadPictureDialog = (props) => {
  const {userData} = useSelector((state: RootState) => state.userData);
  const dispatch = useDispatch<Dispatch<UserActionTypes>>();

  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState<number | number[]>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [loading, setLoading] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      setLoading(true);
      const croppedImage = await getCroppedImg(props.userImage, croppedAreaPixels);
      let storageRef = storage.ref();
      let ImagesRef = storageRef.child(`profilePictures/${userData.uid}.jpeg`);
      let blob = new Blob([croppedImage], {type: "image/jpeg"});
      let task = ImagesRef.put(blob);
      task.then(() => {
        ImagesRef.getDownloadURL().then((firebaseUrl) => {
          firebaseUrl = firebaseUrl.replace(storageFB, endPoint);
          users
            .doc(userData.uid)
            .set({profilePicture: firebaseUrl}, {merge: true})
            .then(() => {
              userData.profilePicture = firebaseUrl;
              dispatch(updateUser(userData));
              props.onClose();
              props.onCloseRoot();
              setLoading(false);
            });
        });
      });
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const theme = useTheme();

  return (
    <div>
      <div
        style={{
          ...(styles.cropContainer as React.CSSProperties),
        }}
      >
        <Cropper
          image={props.userImage}
          crop={crop}
          // @ts-ignore
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          cropShape="round"
          showGrid={false}
        />
      </div>
      <div
        style={{
          ...(styles.controls as React.CSSProperties),
        }}
      >
        <div
          style={{
            ...(styles.sliderContainer as React.CSSProperties),
          }}
        >
          <h5 style={{fontWeight: "bold"}}>Zoom</h5>

          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            style={{
              ...(styles.slider as React.CSSProperties),
            }}
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </div>
        <SecondaryButton
          isLoading={loading}
          background={isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main}
          textColor={"white"}
          disabled={false}
          style={{maxWidth: "unset", width: "100%", border: "2.5px solid rgb(240,240,240)"}}
          onClick={showCroppedImage}
        >
          Upload
        </SecondaryButton>
      </div>
    </div>
  );
};

export default UploadPictureDialog;