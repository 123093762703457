import React, { useEffect, useState } from "react";
import Text from "../data-display/text";
import {useTheme} from "@mui/system";
import MiniButton from "../buttons/mini-button";
import PrimaryButton from "../buttons/primary-button";
import Dropzone, { IFileWithMeta } from "react-dropzone-uploader";
import { FaInfo, FaCheckCircle } from "react-icons/fa";
import {FiX} from "react-icons/fi";
import Alert from "@mui/material/Alert";
import { myPortfolio, users } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/index.reducers";
import firebase from "firebase/app";
import CircularProgress from "@mui/material/CircularProgress";
import DialogBottomButton from "../dialogs/dialog-bottom-button";
import {endPoint, isQuickTaskProject, storageFB} from "../../utils/constant.urls";
import { environment } from "../../environments/quickTaskEnvironemt";
const QuikTaskMainColor = environment.mainColor;
const TheMainColor = isQuickTaskProject ? QuikTaskMainColor : "rgb(78, 62, 253)";

;

const PortfolioForm = ({user, updateUser, onContinue}) => {
   const theme = useTheme();
  const styles = {
    previewDeleteIcon: {
      cursor: "pointer",
      borderRadius: "50%",
      background: "rgba(0, 0, 0,0.8)",
      position: "absolute",
      right: "8px",
      top: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "20px",
      width: "20px",
      transition: "0.3s",
      "&:hover": {
        transform: "scale(1.1)",
      },
    },
    previewContainer: {
      position: "relative",
      height: "120px",
      boxShadow: "0 4px 11px 0.2px rgba(0,0,0,0.06)",
      display: "inline-block",
      margin: "8px",
      overflow: "hidden",
      borderRadius: "8px",
      width: "120px",
    },
    progressBar: {
      color: "white",
    },
    progressBackground: {
      color: "rgba(255,255,255,0.5)",
    },
  };;

  const {userData, isAuthenticated, isVerified} = useSelector((state: RootState) => state.userData);
  const [loading, setLoading] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<(IFileWithMeta | string)[]>([]);
  const [progress, setProgress] = useState<number[]>([0, 0, 0, 0]);
  const [attachmentsError, setAttachmentsError] = useState<{
    file: (string | IFileWithMeta)[] | null;
    errorMsg: string;
  } | null>(null);
  const [open, setDialog] = useState(false);
  const openDialog = () => setDialog(true);
  const [uploadedMedia, setUploadedMedia] = useState<string[]>([]);

  useEffect(() => {
    setAttachments(userData.portfolio);
  }, [userData]);

  const handleRemove = (f: IFileWithMeta | string) => (event: any) => {
    event.preventDefault();
    if (typeof f !== "string" && attachmentsError?.file?.includes(f)) {
      setAttachmentsError(null);
    }
    if (typeof f !== "string") f.remove();
    else {
      let a = [...attachments];
      a.splice(a.indexOf(f), 1);
      setAttachments([...a]);
      users.doc(userData.uid).set({portfolio: [...a]}, {merge: true});
    }
  };

  const handleChangeStatus = (file: IFileWithMeta, status: string, allFiles: IFileWithMeta[]) => {
    if (status === "removed") {
      let f = attachments.find((a) => typeof a !== "string" && a.meta.id === file.meta.id);
      let a = [...attachments];
      a.splice(a.indexOf(f), 1);
      setAttachments([...a]);
    } else if (status === "done") {
      let a = [...attachments];
      allFiles.forEach((file) => {
        if (!a.find((item) => typeof item !== "string" && item.meta.id === file.meta.id)) {
          a.push(file);
        }
      });
      setAttachments([...a]);
    }
  };

  const goNext = () => {
    if (user) {
      if (attachments.length === 0) {
        user.portfolio = [];
        updateUser(user);
        onContinue();
      } else {
        let uploaded: string[] = [];
        let hasLargeVideo = attachments?.find((file) => {
          if (
            typeof file !== "string" &&
            file?.meta?.type?.includes("video") &&
            file?.meta?.duration > 105
          ) {
            return file;
          }
        });
        let multipleVideos = attachments?.filter((file) => {
          if (typeof file !== "string" && file?.meta?.type?.includes("video")) {
            return file;
          }
        });
        if (hasLargeVideo) {
          setAttachmentsError(
            typeof hasLargeVideo !== "string"
              ? {file: [hasLargeVideo], errorMsg: "the max video duration is 1:30 m:s!"}
              : null
          );
        } else if (multipleVideos?.length > 1) {
          setAttachmentsError({
            file: multipleVideos?.filter((f) => {
              if (typeof f !== "string") return f;
            }),
            errorMsg: "not allowed to attach more than 1 video.",
          });
        } else {
          attachments.forEach((a, index) => {
            if (typeof a === "string") {
              uploaded.push(a);
              if (uploaded?.length === attachments?.length) {
                user.portfolio = uploaded;
                updateUser(user);
                onContinue();
              }
            } else {
              let upload = myPortfolio(userData.uid).child(a.file.name).put(a.file);
              upload.on(
                firebase.storage.TaskEvent.STATE_CHANGED,
                (snapshot) => {
                  setProgress((p) => {
                    p[index] = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    return p;
                  });
                  switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED:
                      break;
                    case firebase.storage.TaskState.RUNNING:
                      break;
                  }
                },
                (error) => {
                  console.log(error);
                },
                () => {
                  upload.snapshot.ref
                    .getDownloadURL()
                    .then((downloadURL) => {
                      downloadURL = downloadURL.replace(storageFB, endPoint);
                      uploaded.push(downloadURL);
                    })
                    .then((result) => {
                      if (uploaded.length === attachments.length) {
                        user.portfolio = uploaded;
                        users.doc(userData.uid).set({portfolio: uploaded}, {merge: true});
                        updateUser(user);
                        onContinue();
                      }
                    });
                }
              );
            }
          });
        }
      }
    }
  };

  return (
    <div style={{paddingTop: "16px"}}>
      <Text
        variant={"body2"}
        style={{
          color: isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main,
          marginBottom: "4px",
        }}
        medium
      >
        Portfolio
      </Text>
      <Dropzone
        multiple={true}
        inputContent={
          <p
            key={"dropzone"}
            style={{
              textAlign: "center",
              padding: "0 16px",
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Text
              component={"span"}
              variant={"body2"}
              bold
              style={{
                marginRight: "8px",
                textAlign: "left",
                color: loading || attachments.length === 4 ? "rgb(140,140,140)" : TheMainColor,
              }}
            >
              Drag n' Drop your photos here
            </Text>
            <MiniButton component={"span"} disabled={loading || attachments.length === 10}>
              Pick Photos
            </MiniButton>
          </p>
        }
        inputWithFilesContent={
          <p
            key={"dropzone"}
            style={{
              textAlign: "center",
              padding: "0 16px",
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Text
              component={"span"}
              variant={"body2"}
              bold
              style={{
                marginRight: "8px",
                textAlign: "left",
                color: loading || attachments.length === 4 ? "rgb(140,140,140)" : TheMainColor,
              }}
            >
              Drag n' Drop your photos here
            </Text>
            <MiniButton component={"span"} disabled={loading || attachments.length === 10}>
              Pick Photos
            </MiniButton>
          </p>
        }
        PreviewComponent={null}
        submitButtonDisabled={true}
        onChangeStatus={handleChangeStatus}
        disabled={loading || attachments.length === 10}
        accept="image/jpg,image/JPEG,image/jpeg,image/png,image/PNG,image/gif,image/GIF,image/tiff,image/TIFF,image/raw,image/RAW,video/*"
        styles={{
          input: {display: "none"},
          dropzone: {
            minHeight: 32,
            borderRadius: "8px",
            border: "2px dashed rgb(220,220,220)",
            padding: "8px",
            background: "white",
            overflow: "hidden",
          },
          dropzoneDisabled: {background: "white", border: "2px dashed rgb(230, 230, 230)"},
          dropzoneActive: {
            background: "rgba(78, 62, 253,0.13)",
            border: `2px solid ${TheMainColor}`,
          },
          dropzoneReject: {
            background: "rgb(235, 80, 60, 0.13)",
            border: "2px solid rgb(235, 80, 60)",
          },
        }}
      />
      <div style={{display: "flex", marginTop: "14px"}}>
        <FaInfo style={{color: "grey"}} size={14} />
        <Text variant={"caption"} style={{color: "grey", marginLeft: "12px"}} medium>
          You can upload upto 10 images.
        </Text>
      </div>
      {attachmentsError !== null && <Alert severity="error">{attachmentsError?.errorMsg}</Alert>}
      <div
        style={{
          width: "100%",
          boxSizing: "border-box",
          overflowX: "auto",
          padding: "18px 0px",
          whiteSpace: "nowrap",
        }}
      >
        {attachments.map((f, i) => {
          if (typeof f !== "string")
            return (
              <div
                key={i}
                               style={{
                  ...(styles.previewContainer as React.CSSProperties),
                  backgroundImage: `url(${f.meta.previewUrl})`,
                  backgroundSize: "cover",
                }}
              >
                {loading ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      background: "rgba(0,0,0,0.5)",
                      top: 0,
                      position: "absolute",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    {progress[i] === 100 ? (
                      <FaCheckCircle size={32} color={"white"} />
                    ) : (
                      <div style={{transform: "translate(-18px, -18px)"}}>
                        <CircularProgress
                          variant={"determinate"}
                          value={progress[i]}
                          style={{
                            ...(styles.previewDeleteIcon as React.CSSProperties),
                            position: "absolute",
                          }}                          size={40}
                          thickness={12}
                        />
                        <CircularProgress
                          variant={"determinate"}
                          style={{position: "absolute"}}
                          value={100}
                           sx={{
                            colorPrimary: styles.progressBackground,
                          }}                          size={40}
                          thickness={12}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div   style={{
                      ...(styles.previewDeleteIcon as React.CSSProperties),
                    }}
                      onClick={handleRemove(f)}>
                    <FiX style={{height: "14px", width: "14px", color: "white"}} />
                  </div>
                )}
              </div>
            );
          else
            return (
              <div
                key={i}
   style={{
                  ...(styles.previewContainer as React.CSSProperties),
                  backgroundImage: `url(${f})`,
                  backgroundSize: "cover",
                }}
              >
                {loading ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      background: "rgba(0,0,0,0.5)",
                      top: 0,
                      position: "absolute",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <FaCheckCircle size={32} color={"white"} />
                  </div>
                ) : (
                  <div   style={{
                      ...(styles.previewDeleteIcon as React.CSSProperties),
                    }}
                      onClick={handleRemove(f)}>
                    <FiX style={{height: "14px", width: "14px", color: "white"}} />
                  </div>
                )}
              </div>
            );
        })}
      </div>
      <DialogBottomButton type={"submit"} onClick={goNext}>
        {attachments.length > 0 ? "Save" : "Continue"}
      </DialogBottomButton>
    </div>
  );
};

export default PortfolioForm;
